import { removeArrayItemByValue } from "@/utils/HexTool";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";


@Component({
  components: {},
})
export default class TrpgModelEditor extends Vue {

  trpgModelFile = [];
  trpgModelData: any = {};

  tabsData = {
    name: 0
  }

  trpgCardData = {
    roleSelectItems: new Array<string>(),
    role: {
      name: '',
      describe: '',
      attribute: '',
      consumables: ''
    },
    addRoleNameBean: '',
    addEventId: '',
    addOptionId: '',
    eventSelectItems: new Array<String>(),
    event: {
      id: '',
      text: '',
      option: []
    },
    eventOptionItems: new Array<string>(),
    eventOptionLoading: false,
    eventOption: {
      id: '',
      text: '',
      count: 1,
      detection: {
        role: '',
        attribute: '',
        consumables: '',
        showDice: false,
        success: {
          text: '',
          nextNode: '',
          update: {
            role: '',
            attribute: '',
            consumables: ''
          }
        },
        fail: {
          text: '',
          nextNode: '',
          update: {
            role: '',
            attribute: '',
            consumables: ''
          }
        }
      }
    }

  }


  ChangeTrpgModelFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.trpgModelData = JSON.parse(e.target.result);
      _this.roleItemsHandler();
      _this.eventItemsHandler();
      _this.eventOptionItemsHandler();
    }
    reader.readAsText(_this.trpgModelFile[0]);
  }



  roleItemsHandler() {
    const tempRoleObject = this.trpgModelData.importData.role;
    const tempList = [];
    for (const key in tempRoleObject) {
      tempList.push(key);
    }
    this.trpgCardData.roleSelectItems = tempList;
  }

  eventItemsHandler() {
    const tempEventObject = this.trpgModelData.event.node;
    const tempList = [];
    for (const key in tempEventObject) {
      tempList.push(key);
    }
    this.trpgCardData.eventSelectItems = tempList;
  }

  eventOptionItemsHandler() {
    const tempEventObject = this.trpgModelData.option;
    const tempList = [];
    for (const key in tempEventObject) {
      tempList.push(key);
    }
    this.trpgCardData.eventOptionItems = tempList;
  }



  @Watch('trpgCardData.role.name')
  selectRoleNameEvent(value: string) {
    const srcRoleData = this.trpgModelData.importData.role;
    const tempData = {
      name: value,
      describe: srcRoleData[value].describe,
      attribute: srcRoleData[value].attribute,
      consumables: srcRoleData[value].consumables
    }
    this.trpgCardData.role = tempData;
  }

  @Watch('trpgCardData.event.id')
  selectEventId(eventId: string) {
    const srcEventNodeData = this.trpgModelData.event.node;
    const tempData = {
      id: eventId,
      text: srcEventNodeData[eventId].text,
      option: srcEventNodeData[eventId].option
    }
    this.trpgCardData.event = tempData;
  }

  @Watch('trpgCardData.eventOption.id')
  selectEventOptionId(optionId: string) {
    const srcEventNodeData = this.trpgModelData.option;
    const tempData = {
      id: optionId,
      text: srcEventNodeData[optionId].text,
      count: srcEventNodeData[optionId].count,
      detection: srcEventNodeData[optionId].detection
    }
    this.trpgCardData.eventOption = tempData;
  }


  addTrpgRole() {
    const tempSaveRoleData = {
      name: this.trpgCardData.addRoleNameBean,
      describe: '',
      attribute: '',
      consumables: ''
    }
    this.trpgModelData.importData.role[this.trpgCardData.addRoleNameBean] = tempSaveRoleData;
    this.roleItemsHandler();
    this.trpgCardData.role.name = this.trpgCardData.addRoleNameBean;
    this.$toast.success('已增加新角色:' + this.trpgCardData.addRoleNameBean);
  }

  saveRoleData() {
    const tempSaveRoleData = {
      name: this.trpgCardData.role.name,
      describe: this.trpgCardData.role.describe,
      attribute: this.trpgCardData.role.attribute,
      consumables: this.trpgCardData.role.consumables
    };
    this.trpgModelData.importData.role[this.trpgCardData.role.name] = tempSaveRoleData;
    this.$toast.success('保存角色数据成功');
  }

  addTrpgEvent() {
    const tempSaveData = {
      text: '',
      option: []
    };

    this.trpgModelData.event.node[this.trpgCardData.addEventId] = tempSaveData;
    this.trpgCardData.event.id = this.trpgCardData.addEventId;
    this.$toast.success('已增加新事件，请继续填充内容');
    this.eventItemsHandler();
  }


  saveTrpgEventData() {
    const tempSaveData = {
      text: this.trpgCardData.event.text,
      option: this.trpgCardData.event.option
    };
    this.trpgModelData.event.node[this.trpgCardData.event.id] = tempSaveData;
    this.$toast.success('保存数据成功');
  }

  trpgEventAddOptionItem() {
    this.trpgModelData.event.node[this.trpgCardData.event.id].option.push(this.trpgCardData.eventOption.id);

  }

  trpgEventDelOptionItem(optionId: string) {
    this.trpgModelData.event.node[this.trpgCardData.event.id].option = removeArrayItemByValue(this.trpgModelData.event.node[this.trpgCardData.event.id].option, optionId);
  }


  deleteRoleData() {
    delete this.trpgModelData.importData.role[this.trpgCardData.role.name];
    this.roleItemsHandler();
    this.$toast.success('删除角色成功');
  }


  deleteEventData() {
    delete this.trpgModelData.event.node[this.trpgCardData.event.id];
    this.eventItemsHandler();
    this.$toast.success('删除事件成功');
  }

  getEventOptionName(optionId: string) {
    const srcOptionData = this.trpgModelData.option;
    const optionText = srcOptionData[optionId].text;
    return `[${optionId}] - ${optionText}`;
  }

  trpgCardSelectEventOptionButton(optionId: string) {
    this.tabsData.name = 3;
    this.trpgCardData.eventOption.id = optionId;
  }

  saveOptionData() {
    const tempOption = {
      text: this.trpgCardData.eventOption.text,
      count: this.trpgCardData.eventOption.count,
      detection: this.trpgCardData.eventOption.detection
    };

    this.trpgModelData.option[this.trpgCardData.eventOption.id] = tempOption;
    this.$toast.success('已保存选项');
  }

  addOptionData() {
    const newOption = {
      text: '',
      count: 1,
      detection: {
        role: '',
        attribute: '',
        consumables: '',
        showDice: false,
        success: {
          text: '',
          nextNode: '',
          update: {
            role: '',
            attribute: '',
            consumables: ''
          }
        },
        fail: {
          text: '',
          nextNode: '',
          update: {
            role: '',
            attribute: '',
            consumables: ''
          }
        }
      }
    };

    this.trpgModelData.option[this.trpgCardData.addOptionId] = newOption;
    this.eventOptionItemsHandler();

    this.trpgCardData.eventOption.id = this.trpgCardData.addOptionId;
    this.$toast.success('已增加新选项，请继续填充内容');
  }

  deleteOptionData() {
    delete this.trpgModelData.option[this.trpgCardData.eventOption.id];
    this.eventOptionItemsHandler();
    this.$toast.success('删除选项成功');
  }



  exportTrpgModelFile() {

    const blob = new Blob([JSON.stringify(this.trpgModelData)], { type: 'application/json' });

    const url = window.URL.createObjectURL(blob)
    // 上面这个是创建一个blob的对象连链接，
    const link = document.createElement('a')
    // 创建一个链接元素，是属于 a 标签的链接元素，所以括号里才是a，

    link.href = url;
    // 把上面获得的blob的对象链接赋值给新创建的这个 a 链接
    link.setAttribute('download', this.trpgModelData.name + "v" + this.trpgModelData.version + ".json")
    // 设置下载的属性（所以使用的是download），这个是a 标签的一个属性
    // 后面的是文件名字，可以更改
    link.click();

  }


}